import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Link } from 'react-router-dom'
import Axios from 'axios';

class Album extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listas_todas: [],
    };
  }

  componentWillMount () {
    window.H5_loading.show();
    this.listaTodas();
  }

  componentDidMount () {
    window.H5_loading.hide();
  }

  listaTodas() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    //params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'album');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        if(response.data.elError===1){
          this.setState({listas_todas: []})
          console.log(response.data.mensaje)
        }else{
          this.setState({
            listas_todas: response.data.lista_array.map((item, i) => {
              let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
              //link = link.replaceAll(/ /g, '')
              link = link.substring(0, 500)
              link = link+"&"+item.not_id

              let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
              return(
                <Link to={`../noticias/${link}`} className="alice_noticias" key={i}>
                  <div className="titulo_alice">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</div>
                  {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}
                </Link>
              )
            })
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  responsive = {
    0: { items: 1 },
    500: { items: 2 },
    700: { items: 3 },
    1100: { items: 4 },
  }

  render () {
      return (
        <div className="padding_alice">
          {
            this.state.listas_todas.length > 0 ?
              <div className="boton_alice">
                <div className="alice_alice">
                  <AliceCarousel
                    responsive={this.responsive}
                    autoPlayInterval={3000}
                    autoPlayDirection="rtl"
                    autoPlay={true}
                    fadeOutAnimation={true}
                    mouseTrackingEnabtutoled={false}
                    playButtonEnabled={false}
                    disableAutoPlayOnAction={true}
                    dotsDisabled={true}
                    buttonsDisabled={true}
                    items={this.state.listas_todas}
                    ref={(el) => (this.Carousel = el)}
                  />
                </div>
                <button onClick={() => this.Carousel.slidePrev()} className="bu_le"><img src="img/flecha.svg" alt="Atrás" title="Atrás"/></button>
                <button onClick={() => this.Carousel.slideNext()} className="bu_ri"><img src="img/flecha.svg" alt="Siguiente" title="Siguiente"/></button>
              </div>
            :
              null
          }
        </div>
      )
    
  }
}

export default Album;
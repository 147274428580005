import React, { Component } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2'

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario:'',
      contrasena:'',
    };
    this.handleChange = this.handleChange.bind(this);
    this.elLogeo = this.elLogeo.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    if(window.location.href.substr(0, 5)==='http:' && window.location.href!=='http://localhost:3000/admin'){
      window.location.href = window.rutas;
    }

    if(localStorage.usuario){
      window.location.href = window.rutas+"panel";
    }
  }
  
  componentDidMount () {
    //document.title = "Ingresar"
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  elLogeo(e) {
    e.preventDefault()
    const {usuario, contrasena} = this.state;
    if(usuario===''){
      alerta('error','Ingresa tu usuario');
    }else if(contrasena===''){
      alerta('error','Ingresa tu contraseña');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('usuario', usuario);
      params.append('contrasena', contrasena);

      Axios.post(`${window.ws}wsLogin.php`, params)
        .then(response => {
          if(response.data.elError===1){
            localStorage.setItem('usuario', usuario)
            localStorage.setItem('_ROL', response.data.elRol)
            localStorage.setItem('_Nombre', response.data.elNombre)
            localStorage.setItem('_Activa', response.data.elActiva)
            //alerta('success','si');
            window.location.href = window.rutas+"panel";
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  render () {
    return (
      <div>
        <div className="bg_gris">
          <div className="max_width padding_width">
            <form id="formulario" className="tama_form">
              <center>
                <h7>Iniciar sesión con tu cuenta</h7>
              </center>
              <div id="cl"><br/></div>
              Usuario:<br/>
              <input type="text" name="usuario" onChange={this.handleChange} value={this.state.usuario}/>
              Contraseña:<br/>
              <input type="password" name="contrasena" onChange={this.handleChange} value={this.state.contrasena}/>
              <div id="cl"><br/></div>
              <center><button onClick={this.elLogeo} className="button puntero">INICIAR SESIÓN</button></center>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;

import { createStore, combineReducers } from 'redux';

//import verMenu from './reducerVerMenu'
//import verLista from './reducerListaSolicitudes'
//import verConsulta from './reducerConsultaSolicitudes'
//import verListaUsuarios from './reducerListaUsuarios'
//import verUsusReg from './reducerVerUsusReg'

import verSecciones from './reducerSecciones'
import verSeccionesTotal from './reducerSeccionesTotal'
import verSeccionesDestacado from './reducerSeccionesDestacado'
import verSeccionesCantidad from './reducerSeccionesCantidad'

const reducer = combineReducers ({
  //verMenu,
  //verLista,
  //verConsulta,
  //verListaUsuarios,
  //verUsusReg,

  verSecciones,
  verSeccionesTotal,
  verSeccionesDestacado,
  verSeccionesCantidad,
});

const store = createStore(reducer);

export default store;